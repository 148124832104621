import React, { useCallback, useContext, useState } from 'react';

import { UserContext } from '../../contexts/UserProvider';
import BigNumber from 'bignumber.js';
import Modal from './Modal';
import SliderCard from '../SliderCard';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { SOL_MINT } from '../../common/constants';

export default (props: { onButtonCallback: () => void }) => {
  const { updateDefaultSolAmount, defaultBuySolAmount } = useContext(UserContext);
  const { tokens, tokensFetched } = useContext(UserContext);

  const solToken = tokens.find((t) => t.mint === SOL_MINT);

  const [amount, setAmount] = useState(defaultBuySolAmount);

  const updateAmount = useCallback(
    (_amount: BigNumber) => {
      setAmount(_amount);
    },
    [setAmount]
  );

  if (!tokensFetched) {
    return (
      <div className="flex h-screen p-5">
        <div className="m-auto">Loading</div>
      </div>
    );
  }

  if (solToken === undefined) {
    props.onButtonCallback();
    return <></>;
  }

  return (
    <Modal
      emoji={<>💸</>}
      title={<>Buy amount?</>}
      subtitle={<>Pick your spending per token as you swipe right to buy</>}
      content={
        <SliderCard
          inputBalance={BigNumber(solToken?.userToken?.balance ?? 0)}
          inputAmountMax={BigNumber(
            Math.min(Number(solToken?.userToken?.balance ?? 0), 4 * 10 ** solToken.decimals)
          )}
          inputAmount={amount}
          inputAmountMin={BigNumber(0.001 * LAMPORTS_PER_SOL)}
          inputDecimals={9}
          inputSymbol={'SOL'}
          inputImage={null}
          outputAmount={null}
          outputDecimals={null}
          outputSymbol={null}
          outputLoading={false}
          onAmountChange={updateAmount}
          buttonText={'Set'}
          onButtonCallback={(_amount) => {
            if (!_amount.isZero()) {
              updateDefaultSolAmount(_amount);
              props.onButtonCallback();
            }
          }}
        />
      }
    />
  );
};
