import numeral from 'numeral';
import BigNumber from 'bignumber.js';
import React, { useContext, useState, useCallback } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { SOL_MINT } from '../common/constants';
import { useUnifiedWallet } from '@jup-ag/wallet-adapter';
import PortfolioTokens from './PortfolioTokens';
import Formatter from './Formatter';
import { AmountSlider } from './ui/AmountSlider';
import useProxyWallet from '../hooks/proxyWallet';
import { parsePkToFormat } from '../common/misc';
import PrimaryButton from './ui/PrimaryButton';

const PortfolioView = ({ showSlider }: { showSlider?: boolean }) => {
  const { disconnect } = useUnifiedWallet();
  const { setTokenSearch, tokens, portfolioValueUsd, defaultBuySolAmount, updateDefaultSolAmount } =
    useContext(UserContext);
  const { publicKey } = useProxyWallet();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState<string | null>(null);

  const toggleModal = (modalName: string) => {
    setOpenModal(openModal === modalName ? null : modalName);
  };

  const closeModal = (e: any) => {
    if (e.target === e.currentTarget) {
      setOpenModal(null);
    }
  };

  const solBalance = tokens.find((t) => t.mint === SOL_MINT);

  const [amount, setAmount] = useState(defaultBuySolAmount.div(10 ** 9));

  const updateAmount = useCallback(
    (_amount: BigNumber) => {
      setAmount(_amount);
      updateDefaultSolAmount(_amount.multipliedBy(10 ** 9));
    },
    [setAmount]
  );

  return (
    <div className="min-h-screen">
      <div className="flex justify-between">
        <div className="relative inline-block space-x-2">
          <span className="">🫵</span>
          <span className="cursor-pointer text-yellow-500" onClick={() => toggleModal('profile')}>
            {parsePkToFormat(publicKey?.toBase58() ?? '')}
          </span>
          {openModal === 'profile' ? (
            <FaChevronUp
              className="cursor-pointer text-yellow-500 inline"
              onClick={() => toggleModal('profile')}
            />
          ) : (
            <FaChevronDown
              className="cursor-pointer text-yellow-500 inline"
              onClick={() => toggleModal('profile')}
            />
          )}
          {openModal === 'profile' && (
            <div
              className='absolute left-0 z-10 mt-2 origin-top-right rounded-xl shadow-lg ring-1 overflow-clip ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"'
              onClick={closeModal}>
              <div
                className="bg-[#151719] p-2 rounded-lg shadow-lg"
                onClick={(e) => e.stopPropagation()}>
                <button
                  className="text-white px-4 py-2 rounded"
                  onClick={() => {
                    disconnect();
                    setOpenModal(null);
                  }}>
                  Disconnect
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="flex row">
          💰
          <Formatter
            value={
              solBalance?.userToken?.balanceDecimal
                ? parseFloat(solBalance.userToken.balanceDecimal)
                : 0
            }
            numOfDigits={4}
            className="text-base"
          />{' '}
          &nbsp;SOL
        </div>
      </div>

      {showSlider && solBalance !== undefined && solBalance.userToken !== null && (
        <div className="my-2 mt-4">
          <AmountSlider
            amount={amount}
            setAmount={updateAmount}
            decimals={8}
            minAmount={BigNumber(0.001)}
            maxAmount={BigNumber(Math.min(2, parseFloat(solBalance.userToken.balanceDecimal)))}
            showAmount={true}
            imageUrl="/solana-sol-logo.png"
          />
        </div>
      )}
      {showSlider && solBalance === undefined && <div className="my-2 mt-4 h-12 w-1"></div>}

      <div className="flex flex-col items-center mt-4 mb-8">
        <div className="text-7xl font-bold mt-6">
          ${numeral(BigNumber(portfolioValueUsd?.total ?? 0).toFixed(2)).format('0.00a')}
        </div>
        <div
          className={`${(portfolioValueUsd?.changePercentage ?? 0) >= 0 ? 'text-green-500' : 'text-red-500'} text-xl`}>
          {BigNumber(portfolioValueUsd?.changePercentage ?? 0).toFixed(2)}%
        </div>
      </div>

      {solBalance !== undefined && (
        <div>
          <div className="flex justify-center gap-4 px-2">
            <PrimaryButton
              text={`🔍 Buy tokens`}
              callback={() => {
                setTokenSearch(true);
              }}
              fullWidth={true}
            />

            <button
              onClick={() => navigate('/settings')}
              className="px-6 py-4 -mt-1 text-2xl border border-[#2c2e30] flex items-center justify-center bg-[#161616] text-white rounded-3xl hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
              ⚙️
            </button>
          </div>
          <div className="flex justify-center gap-4 px-2 mt-2 mb-4">
            <a
              target="_blank"
              href="https://7l8qnwofwk1.typeform.com/swipefeedback"
              className="w-full px-6 py-2 text-2xl border border-[#2c2e30] flex items-center justify-center px-4 py-4 bg-[#161616] text-white rounded-3xl hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              rel="noreferrer">
              💬️ <span className="ml-4">Gib Feedback</span>
            </a>
          </div>
        </div>
      )}

      <PortfolioTokens />
    </div>
  );
};

export default PortfolioView;
