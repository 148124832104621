import './styles/globals.css';
import LandingPage from './pages/LandingPage';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import OnboardingPage from './pages/OnboardingPage';
import { PrivateRoutes } from './components/PrivateRoutes';
import SettingsPage from './pages/SettingsPage';
import TokenPage from './pages/TokenPage';
import SwipePage from './pages/SwipePage';
import TestPage from './pages/TestPage';
import React from 'react';
import TutorialPage from './pages/TutorialPage';

export type ReferralDataType = {
  referral: string;
  timestamp: number;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/test',
    element: <TestPage />
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/tutorial',
        element: <TutorialPage />
      },
      {
        path: '/onboarding',
        element: <OnboardingPage />
      },
      {
        path: '/settings',
        element: <SettingsPage />
      },
      {
        path: '/main',
        element: <SwipePage />
      },
      {
        path: '/tokens/:mint',
        element: <TokenPage />
      }
    ]
  }
]);

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    referral: params.get('r') // Replace 'referral' with your query param key
  };
};
const App = () => {
  const queryParams = getQueryParams();

  if (queryParams.referral) {
    const referralData: ReferralDataType = {
      referral: queryParams.referral,
      timestamp: Date.now()
    };

    localStorage.setItem('referralData', JSON.stringify(referralData));
  }

  return (
    <div className="flex flex-col h-screen select-none ">
      <div className="flex-1 drawer h-52 flex-col justify-between">
        <div className="items-center  flex flex-col justify-between  mx-auto max-w-md">
          <RouterProvider router={router} />
        </div>
      </div>
    </div>
  );
};

export default App;
